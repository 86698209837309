import React, {Component} from 'react'
import {base, appDatabasePrimaryFunctions} from '../../base';
import { Redirect } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { confirmAlert } from '../utils/react-confirm-alert';
import appBackgroundImage from '../../styles/images/background.png';
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import '../../styles/css/ConfirmAlertCustom.css';
import "../../styles/css/modalstyles.css";
import "../../styles/css/appstyles.css";
import "../../styles/css/loginstyles.css";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
          redirect: false,
          tenantVariables: {},
          inputtedEmail: '',
          inputtedName: '',
          inputtedZipCode: '',
          userEmail: localStorage.getItem('userEmail') || false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(!navigator.cookieEnabled){
        alert("You do not have cookies enabled! Please change the settings of your browser");
      }
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantRulesRef);
    }

    validateEmail(email) {
    let re;
    re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

    sanitizeEmail(emailAddress){
      //get part of email before @ sign, this has different rules then text after the @
      const firstPartOfEmail = emailAddress.split('@')[0];
      //get rest of email to recombine later
      const secondPartOfEmail = emailAddress.split('@')[1];
      //Removes periods because on some mail clients they can be moved around and we will treat them like a seperate email
      const removedPeriodsFirstPartOfEmail = firstPartOfEmail.replace(/\./g, "");
      //Check if there is a plus in the email
      const splitPluses = removedPeriodsFirstPartOfEmail.split('+');
      let finalFirstPart;
      if (splitPluses.length > 1)
      {
        //if there is a plus cut all the nonsense after the plus, this is important because some email clients allow you to put whatever you want after the plus
        finalFirstPart = splitPluses[0];
      }
      else
      {
        //if no plus continue with periods removed from first part of email
        finalFirstPart = removedPeriodsFirstPartOfEmail;
      }
      //recombine email to make the sanitized version
      const sanitizedEmail = finalFirstPart + "@" + secondPartOfEmail;
      //change to upper so people can sneak by case on me
      return sanitizedEmail.toLowerCase();
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event){
      event.preventDefault();
      const inputtedEmail = this.state.inputtedEmail.trim().toLowerCase();
      const tenantVariables = this.props.variables;
      const inputtedName = this.state.inputtedName.trim();
      const inputtedBirthday = this.state.birthday || localStorage.getItem('birthday');
      const inputtedZipCode = this.state.inputtedZipCode.trim();
      const confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
      const agree_to_promotionRef = this.refs.agree_to_promotion.checked;

      if(!tenantVariables.doNotCollectEmail && inputtedEmail === ""){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter An Email!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      const allowedAge = tenantVariables.allowedAge || 21;
      if(tenantVariables.collectBirthday && tenantVariables.formBirthday && inputtedBirthday === null){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter A Birthday!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      } else if(tenantVariables.collectBirthday){
        const age = ~~((new Date().getTime() - +new Date(inputtedBirthday)) / (31557600000))
        if (age < allowedAge) {
          confirmAlert({
            variables: this.props.variables,
            title: 'Input Error',
            message: "Your are not old enough to participate",
            buttons: [
              {
                label: 'OK',
              }
            ]
          })
          return;
        }
      }
      if(!tenantVariables.doNotCollectEmail && !this.validateEmail(inputtedEmail)){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Unable to read email, please check to make sure it is entered correctly",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      if(tenantVariables.collectName && inputtedName === ""){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter A Name!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      if(tenantVariables.collectZipCode && inputtedZipCode === ""){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please Enter A Zip Code!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      if(tenantVariables.mandatoryTermsAndConditions && !confirmMandatoryRef){
        confirmAlert({
          variables: this.props.variables,
          title: 'Missing Info',
          message: "Please agree to rules and regulations!",
          buttons: [
            {
              label: 'OK',
            }
          ]
        })
        return;
      }
      // Play with ' + this.state.inputedEmail + '?'
      if(tenantVariables.doNotCollectEmail){
        this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday)
        return
      }
      var buttons = [
        {
          label: 'NO',
          onClick: () => {
            console.log("Canceled")
            document.body.classList.remove('react-confirm-alert-body-element')
            const target = document.getElementById('react-confirm-alert')
            target.parentNode.removeChild(target)
            const svg = document.getElementById('react-confirm-alert-firm-svg')
            svg.parentNode.removeChild(svg)
            document.body.children[0].classList.remove('react-confirm-alert-blur')
          }
        },
        {
          label: "YES",
          onClick: () => {
            this.createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday)
          }
        }
      ]
      var message = "We'll send your prize there if you win and we don't want to send it to the wrong place\n"+ inputtedEmail + "\nIs that correct?"
      var primaryColor = this.props.variables.primaryColor || "black"
      var secondaryColor = this.props.variables.secondaryColor || "white"
      confirmAlert({
        title: 'Confirm Email',
        message: message,
        customUI: ({ title, message, onClose }) =>
        <div className='react-confirm-alert-body'>
          {title && <h1 style={{fontWeight:'900'}}>{title}</h1>}
          {/*"We'll send your prize there if you win and we don't want to send it to the wrong place"*/}
          <span className="email-in-popup" style={{fontWeight:'900', color:'black', fontFamily:'Oswald'}}>{inputtedEmail}</span>
          <br/>
          <div className='react-confirm-alert-button-group'>
            {buttons.map((button, i) => (
              <button
                key={i}
                style={{backgroundColor:primaryColor, color: secondaryColor}}
                onClick={() => {
                    button.onClick()
                  }
                }
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      })
    }

    createUser(inputtedEmail, inputtedZipCode, inputtedName, agree_to_promotionRef, inputtedBirthday){
      const tenantVariables = this.props.variables || {};
      let base64EncodedEmail = appDatabasePrimaryFunctions.ref().push().key;
      if(inputtedEmail){
        if(tenantVariables.sanitizeEmails){
          inputtedEmail = this.sanitizeEmail(inputtedEmail);
          base64EncodedEmail = btoa(inputtedEmail);
        } else {
          base64EncodedEmail = btoa(inputtedEmail);
        }
      }
      const userObject = {};
      userObject['lastSignIn'] = new Date().getTime();
      userObject['email'] = inputtedEmail;
      userObject['uid'] = base64EncodedEmail;
      userObject['zipCode'] = inputtedZipCode;
      userObject['name'] = inputtedName;
      userObject['optIn'] = agree_to_promotionRef;
      userObject['birthday'] = inputtedBirthday;
      const vm = this;
      base.post('users/' + base64EncodedEmail, {
        data: userObject,
        context: this,
        then(err){
          if(!err){
            document.body.classList.remove('react-confirm-alert-body-element')
            const target = document.getElementById('react-confirm-alert')
            if(target) {
              target.parentNode.removeChild(target)
            }
            const svg = document.getElementById('react-confirm-alert-firm-svg')
            if(svg){
              svg.parentNode.removeChild(svg)
            }
            document.body.children[0].classList.remove('react-confirm-alert-blur')
            try {
              if(inputtedEmail){
                localStorage.setItem('userEmail', inputtedEmail);
              } else {
                localStorage.setItem('userEmail', base64EncodedEmail);
              }
              vm.setState({redirect: true})
            } catch(e){
              console.log(e)
              alert("Unable to log you in! Check your settings and try again!")
            }

          } else {
            alert("Oh No! There was an error please try again!")
          }
        }
      })
    }

    render() {
      const { redirect } = this.state;
      const tenantVariables = this.props.variables || {};
      const tenantRules = this.state.tenantRules || {};
      const link = tenantRules.rulesAndRegsLink;
      const howToPlayLink = tenantRules.howToPlayLink || "";
      let howToPlayText = tenantRules.howToPlayText || "";
      const rulesRegsText = tenantRules.rulesAndRegsText || "Please agree to the Rules and Regulations";
      const promotionText = tenantVariables.promotionText || "Check To Opt-In To More Offers From Us";
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
        return (
            <Redirect to="/age_gate" />
        )
      }
      if (redirect || this.state.userEmail) {
          return (
              <Redirect to="/" />
          )
      }
      const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
      const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
      const primaryColor = tenantVariables.primaryColor || "black";
      const secondaryColor = tenantVariables.secondaryColor || "white";
      if(howToPlayText === "<p><br></p>"){
        howToPlayText = "";
      }

      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
            <div className="flex-content-container-home">
              <div className="intro-container-home">
                <div className="hero-text-container" style={{paddingTop:0}}>
                  <img src={frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <div className="container-out">
                  <div className="question-box question-form" style={{padding:'15px'}}>
                    <h4 style={{fontFamily: "Oswald", fontWeight: 700}}>Enter your email to play</h4>
                    {/*<p className="text-muted" style={{fontFamily: "Oswald"}}>So we know where to send your prize</p>*/}
                    <form onSubmit={this.handleSubmit} id="user-values-form">
                      <div className="input-group" style={{display: tenantVariables.doNotCollectEmail ? 'none' : ""}}>
                        <input id="email" name="inputtedEmail" type="email" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Email" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectName ? '' : "none"}}>
                        <input id="name" name="inputtedName" type="text" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Name" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectZipCode ? '' : "none"}}>
                        <input id="zipCode" name="inputtedZipCode" type="text" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Zip Code" />
                      </div>
                      <div className="input-group" style={{display: tenantVariables.collectBirthday && tenantVariables.formBirthday ? '' : 'none'}}>
                        <input id="birthday" name="birthday" type="date" className="form-control" style={{fontFamily: "Oswald"}} onChange={this.handleChange} />
                      </div>
                      <div style={{display:tenantVariables.mandatoryTermsAndConditions?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
                          <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{window.open(link, '_blank');}}>{rulesRegsText}</span></u></strong></label>
                        </div>
                      </div>
                      <div style={{display:tenantVariables.collectOptIn?'table-row':"none",textAlign:'left'}}>
                        <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>
                          <input type="checkbox" ref="agree_to_promotion" defaultChecked={!!tenantVariables.collectOptIn} style={{width:20,height:20}}/>
                        </div>
                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                          <label htmlFor="agreeToPromotionId">{promotionText}</label>
                        </div>
                      </div>
                      <button style={{backgroundColor: primaryColor, color: secondaryColor}} className="btn btn-play" id="submitButton"><strong>LET'S PLAY!</strong></button>
                    </form>
                    <button className="btn btn-rules" style={{display: howToPlayLink ? "" : "none"}} onClick={()=>{howToPlayText? this.toggle():window.open(howToPlayLink, '_blank');}}><strong>How To Play</strong></button>
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <ModalHeader/>
              <ModalBody style={{textAlign:"center"}}>
                <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                    <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                    <button className="btn btn-play" onClick={() => { this.toggle(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>OK</button>
                </div>
              </ModalBody>
              <ModalFooter style={{borderTop:'none'}}/>
            </Modal>
          </div>
      )
    }
}

export default Login;

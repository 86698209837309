import React, {Component} from 'react';
import {confirmAlert} from "../utils/react-confirm-alert";
import { Redirect } from 'react-router-dom';
import Confetti from 'react-confetti';
import { unmountComponentAtNode } from "react-dom";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import "../../styles/css/modalstyles.css";
import '../../styles/css/ConfirmAlertCustom.css';
import { base } from '../../base';
import QrReader from 'react-qr-reader';
import { getDistance } from 'geolib';
import appBackgroundImage from '../../styles/images/background.png'
import Loading from '../utils/Loading';

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: localStorage.getItem('userEmail') || false,
            width: 0,
            height: 0,
            startConfetti: false,
            currentGameId: null,
            squares: [],
            itemSelectedPopUpShown: false,
            itemSelectedToShow: {},
            result: 'No result',
            showQrScanner: false,
            userSquares:[],
            showSuccessfulRedeemModal: false,
            blackOutComplete: false,
            locationChecking: true,
            gameActive: false,
            loading: true,
            howToPlayModel: false
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    changeMilesToMeters(milesToConvert){
        return milesToConvert*1609.344;
    }

    getLocation(){
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Geolocation is not supported by your browser. Please change browsers to play!");
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                            latitude: toCheckLatitude,
                            longitude: toCheckLongitude,
                        }))
                    },
                    (err) => {
                        if(err.message === "User denied Geolocation"){
                            reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                        } else {
                            console.log(err.message);
                            reject("An unknown error occurred, check your internet connection and try again");
                        }
                    }
                );
            }
        })
    }

    getLocationPermission(){
        const locationErrorTitle = "Location Error";
        this.getLocation().then(distance_meters => {
            localStorage.setItem('locationPermissions', "true");
            const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
            if(distance_meters <= allowed_distance){
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false,
                    gameActive: true
                });
            } else {
                this.setState({
                    modal:false,
                    loading:false
                });
                confirmAlert({
                    title: locationErrorTitle,
                    variables: this.props.variables,
                    message: "Too far from game area to participate!",
                    buttons: [
                        {
                            label: 'Retry',
                            onClick: () => {
                                this.checkUsersLocation()
                            }
                        }
                    ]
                });
            }
        }, error => {
            console.log(error);
            this.setState({
                modal:false,
                loading:false
            });
            localStorage.setItem('locationPermissions', "false");
            if(typeof error != "string"){
                error = error.message
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: 'Retry',
                        onClick: () => {
                            this.checkUsersLocation()
                        }
                    }
                ]
            });
        })
    }

    checkUsersLocation(){
        const variables = this.props.variables;
        const locationPermissions = localStorage.getItem('locationPermissions');
        if(!this.props.variables.collectDistance) {
            this.setState({locationChecking: false})
        } else if(locationPermissions === "false" || !locationPermissions){
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.setState({
                                loading: true,
                            });
                            this.getLocationPermission()
                        }
                    }
                ],
            })
        } else {
            this.setState({
                loading: true,
            });
            this.getLocationPermission()
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    logout(){
        localStorage.removeItem('userEmail');
        this.setState({
            userEmail:false,
        })
    }

    componentDidMount() {
        this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
            context: this,
            then(key){
                if(typeof key === "string"){
                    this.logUserActivity(key);
                    if(this.state.currentGameId){
                        this.removeReactAlert();
                    }
                    this.setState({
                        currentGameId:key,
                        startConfetti: false
                    })
                }
            }
        });

        this.addedSquaresRef = base.listenTo(`currentGame/addedSquares`, {
            context: this,
            then(squares){
                this.setState({
                    squares:squares
                })
            }
        });

        this.gameActiveRef = base.listenTo(`currentGame/active`, {
            context: this,
            then(dataActive){
                if(this.props.variables && this.props.variables.collectDistance && dataActive){
                    this.checkUsersLocation();
                } else {
                    this.setState({
                        gameActive: dataActive,
                        locationChecking: false,
                        loading: false
                    })
                }
            }
        });

        this.mapImageRef = base.bindToState('currentGame/mapImage', {
            context: this,
            state: 'mapImage'
        })

        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });

        if(this.state.userEmail){
            this.bindToUserAnswers();
        }
        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.scrollTo(0,0)
    }

    logUserActivity(gameid){
        const vm = this;
        if(gameid){
            const userEmailToBase64 = btoa(this.state.userEmail);
            base.post('userGameHistory/'+userEmailToBase64+`/${gameid}` , {
                data:gameid,
                then(err){
                    if(!err){
                        vm.setState({
                            alreadyUpdate: gameid,
                        });
                        console.log("user game logged!")
                    }
                }
            })
        }
    }

    bindToUserAnswers(){
        this.userSquaresRef = base.bindToState(`userSquares`, {
            context: this,
            queries: {
                orderByChild: 'uid',
                equalTo: btoa(this.state.userEmail)
            },
            asArray: true,
            state: 'userSquares',
            then(){
                if(!this.state.blackOutComplete){
                    this.checkIfBlackout();
                }
            }
        });
    }

    removeReactAlert(){
        document.body.classList.remove('react-confirm-alert-body-element');
        const target = document.getElementById('react-confirm-alert');
        if(target){
            unmountComponentAtNode(target);
            target.parentNode.removeChild(target)
        }
        const svg = document.getElementById('react-confirm-alert-firm-svg');
        if(svg){
            svg.parentNode.removeChild(svg);
            document.body.children[0].classList.remove('react-confirm-alert-blur')
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        base.removeBinding(this.currentGameKeyRef);
        base.removeBinding(this.addedSquaresRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.gameActiveRef);
        base.removeBinding(this.mapImageRef);
        if(this.userSquaresRef){
            base.removeBinding(this.userSquaresRef);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!navigator.cookieEnabled){
            alert("This site requires cookies be enabled to work. Please change your settings in your browser and try again");
        }
    }

    showItemSelectedPopUp(item){
        this.setState({
            itemSelectedToShow: item,
            itemSelectedPopUpShown: true
        })
    }

    showItemCompletedPopUp(item){
        this.setState({
            itemSelectedToShow: item,
            showSuccessfulRedeemModal: true
        })
    }

    activateQrCodeScanner(){
        this.setState({
            showQrScanner: true,
            itemSelectedPopUpShown: false,
        })
    }

    submitCodeToRedeem(){
        const item = this.state.itemSelectedToShow;
        const userAnswerObject = {};
        userAnswerObject['uid'] = btoa(this.state.userEmail);
        userAnswerObject['squareId'] = item.id;
        userAnswerObject['timeStamp'] = Date.now();
        userAnswerObject['code'] = this.state.code;
        const vm = this;
        base.push('userSquares', {
            data: userAnswerObject,
            then(err){
                if(!err){
                    vm.setState({
                      startConfetti: true,
                      code: null,
                  }, ()=>{
                      vm.setState({
                          showSuccessfulRedeemModal: true
                      });
                  })
                } else {
                    confirmAlert({
                        variables: vm.props.variables,
                        title: "Incorrect Code",
                        message: "Make sure you scanned the right item and try again!",
                        buttons: [
                            {
                                label: "OK",
                            }
                        ]

                    });
                }
            }
        })
    }

    checkIfBlackout(){
        const addedSquares = this.state.squares;
        const userSquares = this.state.userSquares;
        if(addedSquares.length > 0){
            if(userSquares && userSquares.length > 0){
                if(addedSquares.length === userSquares.length){
                    const userResponse = {};
                    const userEmail = this.state.userEmail;
                    const base64EncodedEmail = btoa(userEmail);
                    userResponse['timeStamp'] = Date.now();
                    userResponse['uid'] = base64EncodedEmail;
                    const vm = this;
                    this.setState({loading: true})
                    fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/checkIfGetsBlackoutPrize`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(userResponse)
                    }).then(res => {
                        vm.setState({
                            loading: false
                        })
                        Promise.resolve(res.json()).then(function (value) {
                            console.log(value)
                            if(value && value.result === "main_reward_sent"){
                                vm.setState({
                                    blackOutComplete: true,
                                    startConfetti: true,
                                    winner: true
                                })
                            } else {
                                vm.setState({
                                    blackOutComplete: true,
                                    startConfetti: false,
                                    winner: false
                                })
                            }
                        })
                    }).catch(err =>{
                        console.log(err)
                        vm.setState({
                            loading: false
                        })
                    })
                }
            }
        }
    }

    showMap(){
        this.setState({
            showMap: !this.state.showMap,
        });
    }

    handleScan = data => {
        if (data) {
            this.setState({
                showQrScanner:false,
                code: data
            }, ()=>{
                this.submitCodeToRedeem();
            })
        }
    };

    handleError = err => {
        if(err.message === "Permission denied" || err.message === "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."){
            confirmAlert({
                variables: this.props.variables,
                title: "Need To Give Permission",
                message: "We need access to your camera to scan the codes.  Please change your settings in your browser",
                buttons: [
                    {
                        label: "OK",
                    }
                ]
            });
        } else if(err.message) {
            confirmAlert({
                variables: this.props.variables,
                title: "An Error Occurred",
                message: err.message,
                buttons: [
                    {
                        label: "OK",
                    }
                ]
            });
        } else {
            confirmAlert({
                variables: this.props.variables,
                title: "Error Occurred",
                message: "An error occurred accessing your camera on your phone. If you are on iOS please make sure to use Safari",
                buttons: [
                    {
                        label: "OK",
                    }
                ]
            });
        }
    };

    render() {
        if(!this.state.userEmail){
            return (
                <Redirect to="/login" />
            )
        }
        const tenantVariables = this.props.variables || {};
        const verifiedAge = this.props.checkForAgeGate(tenantVariables);
        if(!verifiedAge){
            return (
                <Redirect to={"/age_gate"} />
            )
        }
        const tenantRules = this.state.tenantRules || {};
        const howToPlayLink = tenantRules.howToPlayLink || "";
        let howToPlayText = tenantRules.howToPlayText || "";
        if(howToPlayText === "<p><br></p>"){
            howToPlayText = "";
        }
        const logOutButtonColor = tenantVariables.logOutButtonColor || "#fff";
        const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading} backgroundImage={backgroundImage} variables={tenantVariables}/>
            )
        }

        const userSquares = this.state.userSquares || [];
        const userSquaresIdArray = [];
        if(userSquares.length > 0){
            for(const userSquaresIndex in userSquares){
                userSquaresIdArray.push(userSquares[userSquaresIndex].squareId)
            }
        }
        let completedHeader;
        let completedBody;
        if(this.state.winner){
            completedHeader = tenantVariables.winningHeader || "Congrats!";
            completedBody = tenantVariables.winningBody || "You have successfully blacked out your card!";
        } else{
            completedHeader = tenantVariables.winningNoPrizeHeader || "You Won!";
            completedBody = tenantVariables.winningNoPrizeBody || "But all the prizes have been given out!";
        }
        return (
            <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
                {this.state.startConfetti &&
                    <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
                }
                <div style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-between"}}>
                    <div style={{visibility: typeof this.state.mapImage === "string" ? "visible" : "hidden", justifyContent:"flex-start"}}>
                        <button onClick={()=>this.showMap()} className="btn btn-logout" style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Show Map</button>
                    </div>
                    <div style={{justifyContent:"flex-end"}}>
                        <div className="btn btn-logout" style={{color:logOutButtonColor, borderColor: logOutButtonColor}} onClick={()=>this.logout()}>LOG OUT</div>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"center"}}>
                    <img className="headerImage" src={tenantVariables.topHeaderImage} alt=""/>
                </div>
                <div className="vertical_spacer"/>
                <div style={{textAlign:"center"}}>
                    <button className="btn btn-logout" style={{display: howToPlayLink ? "" : "none", marginTop:0, color:logOutButtonColor, borderColor: logOutButtonColor}} onClick={()=>{howToPlayText? this.setState({howToPlayModel:true}):window.open(howToPlayLink, '_blank');}}><strong>How To Play</strong></button>
                </div>
                <div className="vertical_spacer"/>
                {
                    this.state.squares.length > 0 && this.state.gameActive ? (
                        <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-between", maxWidth:768, marginLeft:"auto", marginRight:"auto"}}>
                            {
                                this.state.squares.sort(function (a, b) {
                                    return a.order - b.order;
                                }).map(function (item, index) {
                                    const itemAvailable = userSquaresIdArray.indexOf(item.id) === -1;
                                    return <div key={index} style={{width:"26%",maxWidth:"33%",flexGrow:1, marginLeft:5,marginRight:5}} onClick={()=>itemAvailable?this.showItemSelectedPopUp(item):this.showItemCompletedPopUp(item)}>
                                        <img width="100%" src={itemAvailable?item.image:tenantVariables.filledInIcon} alt=""/>
                                    </div>
                                },this)
                            }
                        </div>
                    ) : (
                        <div>
                            <div className="headerText" style={{color:tenantVariables.textColor}}>{tenantVariables.noSquareHeader || "No Board Up"}</div>
                            <div className="headerBody" style={{color:tenantVariables.textColor}}>{tenantVariables.noSquareBody || "Come back later to play"}</div>
                        </div>
                    )
                }
                <Modal isOpen={this.state.howToPlayModel} toggle={()=>this.setState({howToPlayModel:false})} style={{width: '90%'}} id="myModal">
                    <ModalHeader/>
                    <ModalBody style={{textAlign:"center"}}>
                        <div className="container-out" style={{paddingLeft: 20, paddingRight: 20}}>
                            <span dangerouslySetInnerHTML={{ __html:howToPlayText}}/>
                            <button className="btn btn-play" onClick={() => { this.setState({howToPlayModel:false}); }} style={{color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>OK</button>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{borderTop:'none'}}/>
                </Modal>
                <Modal isOpen={this.state.showMap} toggle={()=>this.setState({showMap:false})}>
                    <img style={{padding:10, margin:"auto"}} width={300} height={300} src={this.state.mapImage} alt=""/>
                </Modal>
                <Modal isOpen={this.state.itemSelectedPopUpShown} toggle={()=>this.setState({itemSelectedPopUpShown:false})} style={{width: '90%'}}>
                        <div style={{textAlign:"center", padding:20}}>
                            <div className="itemPopUpTitle">{this.state.itemSelectedToShow.squareName}</div>
                            <div>
                                <img width="80px" src={this.state.itemSelectedToShow.image} alt=""/>
                            </div>
                            <div className="vertical_spacer"/>
                            <div>
                                <button onClick={()=>this.activateQrCodeScanner()} style={{color:tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor}} className="btn btn-play">Scan Code</button>
                            </div>
                            <div style={{marginTop:10}}>
                                <button onClick={()=>this.setState({itemSelectedPopUpShown:false})} style={{color:tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, fontSize:12}} className="btn popUpButton">Cancel</button>
                            </div>
                        </div>
                </Modal>
                <Modal isOpen={this.state.showSuccessfulRedeemModal} style={{width: '90%'}}>
                    <div style={{textAlign:"center", padding:20}}>
                        <div className="itemPopUpTitle">{this.state.itemSelectedToShow.squareName}</div>
                        <div>
                            <img width="80px" src={this.state.itemSelectedToShow.image} alt=""/>
                        </div>
                        <div className="vertical_spacer"/>
                        <div className="popUpHeader" style={{marginBottom:5}}>Completed! 👍</div>
                        <button onClick={()=>{this.setState({showSuccessfulRedeemModal:false,itemSelectedToShow:{},startConfetti:false}); this.checkIfBlackout()}} style={{color:tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor}} className="btn btn-play">OK</button>
                    </div>
                </Modal>
                <Modal isOpen={this.state.blackOutComplete} style={{width: '90%'}}>
                    <div style={{textAlign:"center", padding:20}}>
                        <div className="itemPopUpTitle">{completedHeader}</div>
                        <div className="popUpHeader" style={{marginBottom:5}}>{completedBody}</div>
                        <button onClick={()=>this.setState({blackOutComplete:false,startConfetti:false})} style={{color:tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor}} className="btn btn-play">OK</button>
                    </div>
                </Modal>
                {this.state.showQrScanner &&
                    <div className="fullscreen">
                        <div style={{maxWidth: "100%"}}>
                            <img width="80px" src={this.state.itemSelectedToShow.image} alt=""/>
                            <h4 className="aboveQrcodeText">{this.state.itemSelectedToShow.squareName}</h4>
                            <h4 className="aboveQrcodeText">{tenantVariables.scannerText || "Use this scanner to scan the item QR code"}</h4>
                            <QrReader className="qrScanner" delay={300} onError={this.handleError} onScan={this.handleScan}>
                            </QrReader>
                            <div className="vertical_spacer"/>
                            <button style={{color:tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor}} className="btn popUpButton" onClick={()=>this.setState({showQrScanner:false})}>CANCEL</button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Main_App;

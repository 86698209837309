import React, {Component} from 'react';
import Loading from './components/utils/Loading';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import {base} from './base';
import ReactGA from 'react-ga';
import './styles/css/appstyles.css';

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView() {
    if(process.env.NODE_ENV === "production"){
        ReactGA.set({ page: window.location.pathname });
        ReactGA.pageview(window.location.pathname)
    }
}

class App extends Component {
  constructor(props) {
        super(props);
        this.state = {
          authenticated: false,
          variables: {},
          loading: true,
        }
    }

  componentDidMount() {
    this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
      context: this,
      state: 'tenantVariables',
      then(variables){
          variables.backgroundColor = variables.backgroundColor || "black";
          variables.primaryColor = variables.primaryColor || "transparent";
          variables.secondaryColor = variables.secondaryColor || "transparent";
          variables.textColor = variables.textColor || "white";
          document.title = variables.pageTitle || "Sponsor Blackout";
          this.setState({
            authenticated: false,
            variables: variables,
            loading: false,
        })
      }
    });
  }

  checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age = localStorage.getItem('verifiedAge') || false;
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        console.log(user_age, variable_age)
        return (user_age && user_age >= variable_age) || formBirthday;
    }

  componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

  render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading} variables={this.state.variables}/>
        )
    }
    return (
        <div style={{ margin: "0 auto"}}>
            <BrowserRouter onUpdate={logPageView()}>
                <div>
                    <div className="main-content">
                        <div className="workspace">
                            <Switch>
                                <Route
                                    exact
                                    path="/login"
                                    render={(props) => {
                                        return <Login variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                                    }}
                                />
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => {
                                        return <MainApp variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                                    }}
                                />

                                <Route
                                    exact
                                    path="/age_gate"
                                    render={(props) => {
                                        return <AgeVerification variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                                    }} />

                                <Redirect to={`/login`}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </div>
    )
  }
}

export default App;
